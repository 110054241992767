import { useEffect, useState } from "react";
import HeadphoneVibrationImg from "../../assets/images/headphones-vibration.png";
import EarfloatLogo from "../../assets/images/earfloat-logo.svg";
import TaskMgmnt from "../../assets/images/task-page.png";
import ProfileView from "../../assets/images/profile-page.png";
import StorageView from "../../assets/images/storage-page.png";
import VideoBackground from "../../assets/videos/headphones-animation.mp4";
import { Link } from "react-router-dom";

const LandingPage = () => {
  const [currentItemId, setCurrentItemId] = useState(1);
  const [alert, setAlert] = useState(false);
  const items = [
    { id: 1, name: "Project Management" },
    { id: 2, name: "Storage & Encryption" },
    { id: 3, name: "Talent Discovery" },
  ];
  const images = {
    1: TaskMgmnt,
    2: StorageView,
    3: ProfileView,
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentItemId((currentId) => (currentId % 3) + 1);
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const timout = setTimeout(() => {
      setAlert(false);
    }, 5000);

    return () => clearTimeout(timout);
  }, [alert]);

  return (
    <div className="min-h-[100vh] lg:max-h-[100vh] relative p-0 m-0 bg-[#204A7A] lg:bg-none bg-cover bg-no-repeat">
      <video
        autoPlay
        loop
        muted
        className="hidden lg:block absolute inset-0 w-full h-full object-cover"
      >
        <source src={VideoBackground} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="hidden lg:block absolute inset-0 bg-black opacity-5"></div>

      <div className="lg:absolute lg:inset-0 w-full min-h-[100vh] lg:h-full">
        <Link to="/" className="w-full h-[100px] lg:h-[120px] ">
          <img
            src={EarfloatLogo}
            alt="earfloat"
            className="mx-auto w-[150px] lg:w-[300px] max-h-full"
          />
        </Link>

        <div className="w-full lg:h-[calc(100vh-170px)] lg:flex items-center px-5 lg:px-10">
          <div className="w-full lg:w-[33.33%] lg:h-full lg:ml-5 2xl:ml-20 lg:pt-[5%]">
            <h1 className="lg:max-w-[100px] text-[#fff] text-center lg:text-start capitalize text-[30px] lg:text-[55px] font-bold leading-[60px]">
              Streamline your creative workflow.
            </h1>
            <p className="text-[#ECECEC] text-[12px] lg:text-[20px] text-center lg:text-start font-light leading-5 opacity-55">
              CREATIVE COLLABORATION.
            </p>

            <div className="w-full lg:max-w-[300px] mt-10">
              <form name="waitlist" method="POST">
                <input type="hidden" name="form-name" value="waitlist" />
                <p>
                  <label>
                    <input
                      required
                      type="email"
                      name="email"
                      placeholder="Enter your Email..."
                      className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 disabled:bg-gray-200 focus:invalid:border-[red] invalid:text-[red] disabled:cursor-not-allowed"
                    />
                  </label>
                </p>
                <p>
                  <button
                    type="submit"
                    className="w-full  h-10 rounded-md bg-blue-500 mt-5 text-white font-semibold mx-auto"
                  >
                    Join Waitlist
                  </button>
                </p>
              </form>
            </div>
          </div>
          <div className="w-full lg:w-[33.33%] lg:h-full flex items-center justify-center lg:pb-10">
            <img
              src={HeadphoneVibrationImg}
              alt="Headphone Vibration"
              className="w-full block lg:hidden"
            />
          </div>
          <div className="w-full lg:w-[33.33%] lg:h-full flex flex-col items-center justify-end lg:pl-5">
            <ol className="text-center lg:text-start mb-10">
              {items.map((item) => (
                <li
                  key={item.id}
                  className={`${
                    currentItemId === item.id
                      ? "text-[#fff]"
                      : "text-[#ECECEC] opacity-[50%]"
                  } mb-2`}
                >
                  {item.name}
                </li>
              ))}
            </ol>
            <div className="w-full flex justify-end items-end">
              <img
                src={images[currentItemId]}
                alt="features"
                className="lg:w-full h-auto ml-auto mr-0"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
