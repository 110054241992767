import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import UnsubscribePage from "./pages/UnsubscibePage";

function App() {
  const location = window.location;
  return (
    <BrowserRouter>
      <Routes key={location.pathname} location={location}>
        <Route path="/" element={<LandingPage />} />
        <Route path="/unsubscribe" element={<UnsubscribePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
