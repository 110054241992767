import React from "react";
import EarfloatLogo from "../../assets/images/earfloat-logo.svg";
import VideoBackground from "../../assets/videos/headphones-animation.mp4";
import { Link } from "react-router-dom";

const UnsubscribePage = () => {
  return (
    <div className="min-h-[100vh] lg:max-h-[100vh] relative p-0 m-0 bg-[#204A7A] lg:bg-none bg-cover bg-no-repeat">
      <video
        autoPlay
        loop
        muted
        className="hidden lg:block absolute inset-0 w-full h-full object-cover"
      >
        <source src={VideoBackground} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      <div className="hidden lg:block absolute inset-0 bg-black opacity-5"></div>

      <div className="lg:absolute lg:inset-0 w-full min-h-[100vh] lg:h-full">
        <Link to="/" className="w-full h-[100px] lg:h-[120px] ">
          <img
            src={EarfloatLogo}
            alt="earfloat"
            className="mx-auto w-[150px] lg:w-[300px] max-h-full"
          />
        </Link>

        <div className="bg-white max-w-[600px] mx-auto flex flex-col justify-center items-center p-5 shadow-xl rounded-[8px]">
          <p className="max-w-[500px] text-[#505050] text-[15px] lg:text-start font-light leading-5 mb-5">
            If you would like to be removed from our mailing lists, please enter
            your email address & click unsubscribe.
          </p>
          <div className="w-full mx-auto">
            <form name="unsubscribe" method="POST">
              <input type="hidden" name="form-name" value="unsubscribe" />
              <p>
                <label>
                  <input
                    required
                    type="email"
                    name="email"
                    placeholder="Enter your Email..."
                    className="w-full border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200 disabled:bg-gray-200 focus:invalid:border-[red] invalid:text-[red] disabled:cursor-not-allowed"
                  />
                </label>
              </p>
              <p>
                <button
                  type="submit"
                  className="w-full  h-10 rounded-md bg-blue-500 mt-5 text-white font-semibold mx-auto"
                >
                  Unsubscribe
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnsubscribePage;
